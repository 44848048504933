import * as React from "react";
import { Link } from "gatsby";
import H1 from "../components/h1";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Search from "../components/search";

const indices = [{ name: `Pages`, title: `Pages` }];

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404 not found" />
      <H1>お探しのページが見付かりませんでした。</H1>
      <div className="bg-gray-100">
        <div className="container mx-auto max-w-5xl px-5 md:px-10 py-10 md:py-20 bg-white">
          <div className="text-center leading-relaxed md:leading-loose text-sm md:text-base">
            <p>
              申し訳ございません。
              <br />
              <strong>
                サイトをリニューアルしたため検索エンジンのリンクが反映されていない
              </strong>
              かもしれません。
              <br />
              お手数ですが下記の全文検索にキーワードを入れて再度検索してみていただけますでしょうか。
            </p>
          </div>
          <div className="py-10">
            <Search indices={indices} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
