import React from "react";

const H1 = ({ children }) => {
  return (
    <div className="flex flex-col justify-center items-center p-5 md:p-20 bg-cyan-50 text-gray-600">
      <h1 className="md:text-2xl text-lg font-sans font-medium mb-2 text-center tracking-widest">
        {children}
      </h1>
    </div>
  );
};

export default H1;
